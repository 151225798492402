import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { Alert, Box, Link, FormControl, Typography, styled, useMediaQuery } from "@mui/material";
import { DateTime } from "luxon";
import { createHash } from "crypto";
import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import colors from "../../colors";
import CabinetPage from "../../components/Common/CabinetPage";
import { SidePanel } from "../../components/Meeting/BookingSidePanel";
import PollResponseCTA from "../../components/Meeting/PollResponseCTA/PollResponseCTA";
import { EVENT_TYPE, PAGE_URL } from "../../constants";
import theme from "../../theme";
import { 
  CalDateRange, Calendar, ExternalMeetingInfo, Leader, MeetingPollPriority, MeetingQuestionAnswer,
  MeetingQuestionAnswerSubmission, Meetings, MeetingSlot, MeetingStatus, Organization, 
  ParticipantsWithSelections, SchedulingPreferences, SelectedSlots, UICalendarEventConsolidated
} from "../../store";
import { successConfetti } from "../../utils/renderUtils";
import { getNumResponses, humanReadableDuration, TimeZone } from "../../utils/scheduleUtils";
import AttendeeInfoForm from "./AttendeeInfoForm";
import PollSelectionTable from "./PollSelectionTable";
import QuestionResponse from "./QuestionResponse";
import Success from "./Success";
import ParticipantSelector from "./ParticipantSelector";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabModal } from "@CabComponents/CabModal";
import { Controller, useForm } from "react-hook-form";
import { CabTextInput } from "@CabComponents/CabTextInput";
import CabSpinner from "@CabComponents/CabSpinner";
import { IoArrowBack, IoLogInOutline } from "react-icons/io5";
import { Link as RouterLink } from 'react-router-dom';

const ContentBox = styled(Box, {label: "ContentBox"})({
  overflow: 'auto',
  height: '90%',
  overflowX: 'hidden',
  overflowY: 'auto',
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
    backgroundColor: colors.white900,
  },
  '::-webkit-scrollbar-thumb': {
    background: colors.black300,
    borderRadius: 4,
  }
});


const getEmailHash = (email: string) => (
  createHash('sha1').update(email.toLowerCase()).digest('hex')
);

export type PollInfo = {
  from: string;
  name: string;
  description: string;
  durationMinutes: number;
  expectedParticipants: number;
  slots: { start: DateTime; end: DateTime }[];
  participants: ParticipantsWithSelections;
};

export type Attendee = {
  name: string;
  email: string;
  emailHash: string;
  noTimesComment: string | null
};

enum PollStep {
  PARTICIPANT_INFO = 0,
  SELECT_TIMES = 1,
  QUESTIONS = 2,
  CONFIRMATION = 3,
}

export interface PollSubmission {
  attendeeName: string,
  email: string,
  selectedSlots: Map<number, SelectedSlots[number] | null>,
  meetingQuestionAnswers: MeetingQuestionAnswerSubmission[],
  noTimesWork: boolean,
  noTimesComment: string | null
}

export interface ParticipantSelectedSlots {
  [email: string]: SelectedSlots;
}

export type Props = {
  pollInfo: PollInfo;
  onSubmit: (submissions: Array<PollSubmission>) => Promise<void>;
  onUserInfoSubmit: (emails: string[]) => void;
  defaultSlots: ParticipantSelectedSlots;
  onEdit: (emails: string[]) => void;
  isAuthenticated: boolean;
  logo: string;
  returningUserEmailHashes: {[hash: string]: string};
  poll: ExternalMeetingInfo | undefined;
  fetchMeetingQuestionAnswers: (emails: string[])
  => Promise<{ email: string; answers: {[id: number]: MeetingQuestionAnswer} }[]>;
  organization: Organization;
  date?: CalDateRange | null;
  onDateChange?: (d: CalDateRange) => void;
  calendarTimezoneSelected?: TimeZone;
  onCalendarTimezoneSelected: (tz: TimeZone) => void;
  userData?: {
    leaders: Leader[];
    meetingSlots: MeetingSlot[];
    recurringSlots: MeetingSlot[];
    calendarEvents: UICalendarEventConsolidated[];
    meetings: Meetings;
    calendars: Calendar[];
    schedulingPrefs?: SchedulingPreferences;
    selectedLeader: Leader | null,
    onSelectedLeaderChange: (l: Leader | null) => void,
  };
  onLogin: () => void;
};


const PollVoting = ({
  pollInfo: {from, name, description, durationMinutes, expectedParticipants, slots, participants, },
  logo, onUserInfoSubmit, poll, defaultSlots, isAuthenticated, returningUserEmailHashes, 
  fetchMeetingQuestionAnswers, onSubmit, onEdit, organization, calendarTimezoneSelected, onCalendarTimezoneSelected,
  date, onDateChange, userData, onLogin
}: Props): ReactElement => {
  const [step, setStep] = useState(PollStep.PARTICIPANT_INFO);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [isAttendeesValid, setIsAttendeesValid] = useState(false);
  const [isQuestionResponseValid, setQuestionResponseValid] = useState(false);
  const [selectedSlots, setSelectedSlots]
    = useState<{ [email: string]: Map<number, SelectedSlots[number] | null> }>({});
  const [answers, setAnswers] = useState<{ [email: string]: MeetingQuestionAnswerSubmission[] }>({});
  const [noTimesWork, setNoTimesWork] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState(Math.max(300, (window.innerWidth * 0.2)));
  const [saving, setSaving] = useState(false);
  const [addedParticipants, setAddedParticipants] = useState<
    { name: string; email: string; first_response_date: string | null, no_times_comment: string | null }[]>([]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [noTimesDisabled, setNoTimesDisabled] = useState(true);
  const [noTimesHover, setNoTimesHover] = useState(false); 
  const [openNoTimesModal, setOpenNoTimesModal] = useState(false);
  const [slotsInitialized, setSlotsInitialized] = useState(false);

  const {control, getValues, reset, handleSubmit} = useForm<(string | null)[]>({defaultValues: []});

  useEffect(() => {
    const values = getValues();
    if (attendees.length > values.length) {
      const diff = attendees.length - values.length;
      
      reset([...values, ...Array(diff).fill(null)], {keepDirtyValues: true});
    } 
  }, [attendees, getValues, reset]);

  const selectedSlotsLength: {[email: string]: number} = useMemo(() => Object.fromEntries(
    Object.entries(selectedSlots).map(
      ([email, slotsItr]) => {
        const count = [...slotsItr.values()].filter(s => s?.priority).length;
        return [email, count];
      }
    )
  ), [selectedSlots]);

  const selectedCount = useMemo(
    () => Object.values(selectedSlotsLength || {}).reduce((prev, curr) => curr + prev, 0)
    ,
    [selectedSlotsLength]
  );


  const submitComments = (data: (string | null)[], curSelectedCount: number) => {
    const newAttendees = [...attendees];
    for (let i = 0; i < newAttendees.length; i++) {
      newAttendees[i].noTimesComment = data[i];
    }
    setAttendees(newAttendees);
    setNoTimesWork(true);
    setOpenNoTimesModal(false);
    handleNext({fromNoTimesWorkModal: true, curSelectedCount});
  };

  const questionsPresent = (poll?.questions?.length || 0) > 0;
  const durationText = humanReadableDuration(durationMinutes);

  const nextButtonDisabled = (step === PollStep.PARTICIPANT_INFO
      && !poll?.enable_public_attendee_list && (!isAttendeesValid))
    || (step === PollStep.PARTICIPANT_INFO && poll?.enable_public_attendee_list && attendees.length === 0)
    || (step === PollStep.QUESTIONS && !isQuestionResponseValid);
    // || (step === PollStep.SELECT_TIMES && !noTimesWork 
    //   && Object.values(selectedSlots).flatMap(s => Array.from(s.values()))
    //     .filter(v => v !== null && v.priority !== null).length === 0);

  const drawerWidthStr = drawerWidth + 'px';
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

  const showCalendarButton = isAuthenticated;

  const convertedSlots = useMemo(() => slots.map((slot, i) => ({
    ...slot,
    converted: calendarTimezoneSelected ? {
      ...slot,
      start: slot.start.setZone(calendarTimezoneSelected.name),
      end: slot.end.setZone(calendarTimezoneSelected.name)
    } : slot,
    index: i,
  })), [slots, calendarTimezoneSelected]);

  // update participant selectedslots state with default slots (from returning users)
  useEffect(() => {
    if (!Object.keys(defaultSlots).length || !slots.length || slotsInitialized) return;

    setSlotsInitialized(true);

    const updatedParticipantSlots = Object.keys(defaultSlots).map(participantEmail => {
      const tempMap = new Map<number, SelectedSlots[number] | null>();
      slots.forEach((slot, i) => {
        const defaultSlot = defaultSlots[participantEmail].find(s => s.start.equals(slot.start));
        tempMap.set(i, defaultSlot ?? null);
      });
      return ({ [participantEmail]: tempMap });
    }).reduce((a, b) => ({ ...a, ...b }), {});

    setSelectedSlots(updatedParticipantSlots);
  }, [JSON.stringify(defaultSlots), JSON.stringify(slots)]); // eslint-disable-line react-hooks/exhaustive-deps

  // update answers state with answers from returning users
  const handleFetchAnswers = useCallback(async () => {
    const emails = Object.values(returningUserEmailHashes);
    const curAnswers = await fetchMeetingQuestionAnswers(emails);
    const participantAnswers: { [email: string]: MeetingQuestionAnswerSubmission[] } = curAnswers
      .filter(({ answers: ans }) => ans)
      .map(({ email, answers: ans }) => ({
        [email]: poll?.questions?.map(question => {
          const answer = Object.values(ans).find(ansItr => ansItr.question === question.id);
          return answer
            ? {...answer, participant: undefined }
            : { id: -1, question: question.id, text: '' };
        }) || [],
      }))
      .reduce((a, b) => ({ ...a, ...b }), {});
    
    setAnswers(participantAnswers);
    // This was triggering way too often - we only really want it to trigger when the participant changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(returningUserEmailHashes)]);

  useEffect(() => {
    handleFetchAnswers();
  }, [handleFetchAnswers]);

  // resize drawer width when window size changes
  useEffect(() => {
    function handleResize() {
      setDrawerWidth(Math.min(Math.max(300, (window.innerWidth * 0.2)), 450));
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleViewCalendar = () => {
    if (!isAuthenticated) {
      onLogin();
    } else {
      setCalendarOpen(true);
    }
  };
  
  const handleCheckSlot = useCallback((email: string, value: number, priority: MeetingPollPriority | null) => {
    const tempMap = new Map(selectedSlots[email]);
    const storeSlot = tempMap.get(value);

    const newPriority = priority === null
      ? MeetingPollPriority.AVAILABLE
      : (priority === MeetingPollPriority.AVAILABLE )
        ? MeetingPollPriority.POTENTIAL
        : undefined;

    if (storeSlot) {
      tempMap.set(value, {...storeSlot, priority: newPriority});
    } else {
      const slot = slots[value];
      if (slot) {
        // for new slots, we want the ID to be negative and never 0 or -1 (-1 conflicts with recurring slots)
        tempMap.set(value, {...slot, priority: newPriority, id: -(value + 2)});
      }
    }
    setSelectedSlots({ ...selectedSlots, [email]: tempMap });
    setNoTimesWork(false);
  }, [selectedSlots, slots]);

  const setNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const handleNext = useCallback(async ({
    fromNoTimesWorkModal  = false, curSelectedCount = 0
  }: {fromNoTimesWorkModal?: boolean, curSelectedCount?: number}) => {
    if (step === PollStep.SELECT_TIMES) {
      setCalendarOpen(false);
    }
    if (!fromNoTimesWorkModal && step === PollStep.SELECT_TIMES && curSelectedCount === 0) {
      setOpenNoTimesModal(true);
    } else if (step === PollStep.QUESTIONS || (step === PollStep.SELECT_TIMES && !questionsPresent)) {
      const submission: PollSubmission[] = attendees.map(attendee => ({
        attendeeName: attendee.name, 
        email: attendee.email,
        selectedSlots: selectedSlots[attendee.email] || new Map(),
        meetingQuestionAnswers: answers[attendee.email] || [],
        noTimesComment: attendee.noTimesComment,
        noTimesWork,
      }));
      if (
        (fromNoTimesWorkModal && curSelectedCount === 0) ||  step === PollStep.QUESTIONS || curSelectedCount > 0
      ) {
        setSaving(true);
        await onSubmit(submission);
        setAddedParticipants([]);
        setSaving(false);
        setStep(PollStep.CONFIRMATION);
        successConfetti();
      } 
    } else {
      onUserInfoSubmit(attendees.map(a => a.email));
      setNextStep();
    }
  }, [answers, attendees, noTimesWork, onSubmit, onUserInfoSubmit, questionsPresent, selectedSlots, setNextStep, step]);

  const handleEditSelections = useCallback(() => {
    onEdit(attendees.map(a => a.email));
    setStep(PollStep.SELECT_TIMES);
  }, [attendees, onEdit]);

  const CompanyLogo = styled('img', { label: "CompanyLogo" })(
    {
      fontSize: 24,
      maxWidth: 250,
      maxHeight: 74,
      marginBottom: 5,
      justifyContent: 'flex-end',
      marginTop: 5,
      border: `2px solid ${colors.black500}`,
      borderRadius: 6,
      padding: 8,
      backgroundColor: colors.white900
    }
  );

  const fromString = poll?.meeting_organizer_override && poll?.meeting_organizer_override !== ''
    ?  poll.meeting_organizer_override 
    : `${poll?.create_user.first_name} ${poll?.create_user.last_name}`; 

  const handleSelectParticipants = useCallback((selectedParticipants: { name: string; email: string; }[]) => {
    setAttendees(selectedParticipants.map(participant => ({
      name: participant.name, email: participant.email, emailHash: getEmailHash(participant.email), noTimesComment: null
    })));
    // if (!allowMultipleVotes) {
    //   setStep(PollStep.SELECT_TIMES);
    // }
  }, [setAttendees]);

  const numRespondedParticipants = useMemo(() => getNumResponses(participants), [participants]);
  const participantsWithoutActive = participants.filter(
    p => !attendees.find(a => (a.email && a.email === p.email) || (a.emailHash && a.emailHash === p.emailHash))
  );

  const Step = useMemo(() => {
    const InitialStep = poll?.enable_public_attendee_list ?
      <ParticipantSelector
        from={fromString}
        handleSelectParticipants={handleSelectParticipants}
        selectedAttendees={attendees}
        // public attendee list will always have email
        participants={participants as (ParticipantsWithSelections[number] & { email: string })[]}
        addedParticipants={addedParticipants}
        setAddedParticipants={setAddedParticipants}
        allowAddAttendees={poll?.allow_add_participants}
      />
      :
      <AttendeeInfoForm
        from={fromString}
        handleSelectParticipants={handleSelectParticipants}
        setIsAttendeesValid={setIsAttendeesValid}
        localAttendees={attendees}
        setNextStep={handleNext}
      />;

    return [
      InitialStep,
      <PollSelectionTable
        key={'selectionTable'}
        // returningUserEmailHashes={returningUserEmailHashes}
        activeParticipants={attendees}
        attendeesDisplayName={attendees[0]?.name || attendees[0]?.email || ''}
        handleCalendarTimezoneSelected={onCalendarTimezoneSelected}
        participants={participantsWithoutActive}
        convertedSlots={convertedSlots}
        handleCheckSlot={handleCheckSlot}
        selectedSlots={selectedSlots}
        calendarTimezoneSelected={calendarTimezoneSelected}
        isMobile={isMdDown}
        name={name}
        description={description}
        durationText={durationText}
        participantLength={numRespondedParticipants}
        userData={userData}
        date={date}
        onDateChange={onDateChange}
        openCalendar={calendarOpen}
        onCloseCalendar={() => setCalendarOpen(false)}
        onEndReached={() => setNoTimesDisabled(false)}
        enhancePollTableCursors={noTimesHover}
      />,
      <QuestionResponse
        key={'questionResponse'}
        poll={poll}
        participants={attendees}
        defaultAnswers={answers}
        onSubmitAnswers={setAnswers}
        setQuestionResponseValid={setQuestionResponseValid}
      />,
      <Success
        key={'success'}
        poll={poll}
        answers={answers}
        votedSlots={selectedSlots}
        participants={attendees}
        onEditSelections={handleEditSelections}
      />
    ][step];
  }, [
    poll, handleSelectParticipants, attendees, participants, addedParticipants, handleNext, onCalendarTimezoneSelected,
    participantsWithoutActive, convertedSlots, handleCheckSlot, selectedSlots, calendarTimezoneSelected, isMdDown, name,
    description, durationText, numRespondedParticipants, userData, date, onDateChange, calendarOpen, noTimesHover, 
    answers, handleEditSelections, step, fromString
  ]);
  
  const slotsAreSelected = selectedCount > 0;

  const showMeetingInfoPanel = !isMdDown && !calendarOpen;

  return (
    <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
      <Box sx={{ height: '100%', width: '100%', backgroundColor: colors.greyBackdrop }} 
        data-testid="vote-poll-container"
        maxHeight={'100vh'} overflow='auto'>
        {showMeetingInfoPanel && (
          <SidePanel
            logo={logo}
            name={name}
            description={description}
            durationText={durationText}
            participantLength={numRespondedParticipants}
            meetingId={isAuthenticated ? poll?.id : undefined}
          />
        )}
        <Box height={step === PollStep.SELECT_TIMES ? '100%' : 'auto'}
          marginLeft={showMeetingInfoPanel ? drawerWidthStr : 0} padding={isSmDown ? 1.5 : isMdDown ? 4 : 6}>
          {convertedSlots.length && poll?.status.id === MeetingStatus.PENDING ? (
            <>
              {step > PollStep.PARTICIPANT_INFO && step !== PollStep.CONFIRMATION && (
                <Box 
                  display="flex" 
                  marginTop={isSmDown ? 0 : -3} 
                  marginBottom={isSmDown ? 1 : isMdDown ? 1 : 3} 
                  justifyContent={'space-between'}
                  width='100%'
                  alignItems='center'
                >
                  <CabButton
                    onClick={() => setStep(step - 1)}
                    icon={<CabIcon Icon={IoArrowBack} />}
                    buttonType="tertiary"
                    color="accent"
                    size="small"
                    sx={{height: 30}}
                  >
                    Back
                  </CabButton>
                  {step === PollStep.SELECT_TIMES && showCalendarButton && (
                    <CabButton
                      onClick={handleViewCalendar}
                      icon={<CabIcon Icon={IoLogInOutline} />}
                      buttonType="tertiary"
                      color="accent"
                      size="small"
                      sx={{height: 30, marginLeft: 1, display: { xs: 'none', lg: 'inline-flex' }}}
                    >
                      {isAuthenticated ? 'View Executive Calendar' : 'Log in to View Executive Calendar'}
                    </CabButton>
                  )}
                  {step === PollStep.SELECT_TIMES && isMdDown && logo && (
                    <Box display='flex' width='100%' justifyContent='flex-end'>
                      <CompanyLogo src={logo} alt='' />
                    </Box>
                  )}
                </Box>
              )}
              {step === PollStep.SELECT_TIMES ? (
                <ContentBox>
                  {Step}
                </ContentBox>
              ) : (
                <>
                  {Step}
                </>
              )}
              {step === PollStep.CONFIRMATION && (
                <div>
                  <PollResponseCTA
                    isAuthenticated={isAuthenticated}
                    event={EVENT_TYPE.MEETING_POLL_SIGNUP}
                    organizationId={organization.id}
                    organizationName={organization.name}
                    meetingId={poll.id}
                    userId={poll.create_user.id}
                  />
                </div>
              )}
              <Box marginRight={step === PollStep.SELECT_TIMES && calendarOpen ? '425px' : 0}>
                {/* {!submitted && ( */}
                {step !== PollStep.CONFIRMATION && (
                  <Box 
                    marginTop={3} 
                    display='flex' 
                    gap={2} 
                    flexDirection={step === PollStep.SELECT_TIMES ? "row-reverse" : undefined}
                  >
                  
                    <CabButton
                      sx={{ width: '180px', height: '50px' }}
                      onClick={() => handleNext({fromNoTimesWorkModal: false, curSelectedCount : selectedCount})}
                      disabled={nextButtonDisabled || saving}
                    >
                      {
                        (
                          step === PollStep.PARTICIPANT_INFO || (step < PollStep.QUESTIONS && questionsPresent)
                        ) ? 'Continue' : 'Submit'
                      }
                    </CabButton>
                  
                    {step === PollStep.SELECT_TIMES && (
                      <CabTooltip  wrapWithSpan title={
                        selectedCount === 0 ? (
                          noTimesDisabled ? "There are more times available in the future" : "") :
                          "You have already selected at least one available time"
                      }>
                        <CabButton 
                          color='primary'
                          buttonType='tertiary'
                          onMouseEnter={() => setNoTimesHover((noTimesDisabled && !slotsAreSelected) ? 
                            true : false)}
                          onMouseLeave={() => setNoTimesHover(false)}
                          onClick={() => (noTimesDisabled || slotsAreSelected) ? null : setOpenNoTimesModal(true)}
                          sx={ {...(noTimesDisabled || slotsAreSelected ? {
                            backgroundColor: colors.white800,
                            color: colors.white600,
                            borderColor: colors.white600,
                            "&:hover": {
                              backgroundColor: colors.white800,
                              color: colors.white600,
                              borderColor: colors.white600
                            }
                          } : {}),
                          height: 50
                          }}
                        >
                          No Times Work
                        </CabButton>
                      </CabTooltip>
                    )}
                    {saving && (
                      <Box>
                        <CabSpinner scale={2} />
                      </Box>
                    )}
                  </Box>
                
                )}
              </Box>
            </>
          ) : (
            <Box>
              { poll && poll?.status.id !== MeetingStatus.PENDING ? (
                <Alert severity='warning'>
                  This poll is no longer accepting votes. You can email the organizer, 
                  {poll.create_user.first_name}&nbsp; 
                  {poll.create_user.last_name}, at <b>{poll.create_user.email}</b>.
                </Alert>
              ) : (
                <Alert severity='warning'>
                  There are currently no time slots available for this poll.
                </Alert>
              )}
            </Box>
          )}
        </Box>
        <CabModal 
          open={openNoTimesModal}
          closeIcon={true}
          onClose={() => setOpenNoTimesModal(false)}
          title="Send a comment"
        >
          <Box >
            <Typography sx={{marginBottom: 2}}>
              We'll let {poll?.create_user.first_name} know that none of these times work for you. 
              Use the field below to suggest alternative times or provide additional information.</Typography>
            <form onSubmit={(e) => handleSubmit((data) => submitComments(data, selectedCount))(e)}>
              {attendees.filter((attendee) => !selectedSlotsLength[attendee.email]).map(
                (participant, idx) => <Controller
                  key={participant.email}
                  control={control}
                  name={`${idx}`}
                  render={({field: {ref, ...field}}) => <FormControl
                    key={participant.email}
                    fullWidth
                  >
                    <CabTextInput
                      multiline
                      minRows={4}
                      label={attendees.length > 1 ? participant.name : undefined}
                      {...field}
                    />
                  </FormControl>
                  } />)}
              <Box display={"flex"} flexDirection={"row-reverse"} sx={{width: "100%", marginTop: 2}}>
                <CabButton type="submit" sx={{marginLeft: 1}}>Submit</CabButton>
                <CabButton color="primary" buttonType="text" sx={{border: 1}} onClick={() => {
                  reset(Array(attendees.length).fill(null));
                  setOpenNoTimesModal(false);
                }}>Cancel</CabButton>
              </Box>
            </form>
          </Box>
        </CabModal>
      </Box>
      {poll?.id && !showMeetingInfoPanel && isAuthenticated && (
        <Box display='flex' padding={2} 
          sx={{ backgroundColor: colors.greyBackdrop }}>
          <Typography variant="body2">Are you the meeting owner? &nbsp;</Typography>
          <Link component={RouterLink} to={`${PAGE_URL.SCHEDULE}/${poll.id}`}
            variant="body2" color={colors.black900} fontWeight={500}
          >
            Click here
          </Link>
          <Typography variant="body2">&nbsp;to make changes.</Typography>
        </Box>
      )}
    </Box>
  );
};

export type PageProps = {
  loading: boolean;
  pollInfo?: PollInfo;
  isAuthenticated: boolean;
  onEdit: (emails: string[]) => void;
  poll: ExternalMeetingInfo | undefined
  onUserInfoSubmit: (emails: string[]) => void;
  onSubmit: (submissions: Array<PollSubmission>) => Promise<void>;
  defaultSlots: ParticipantSelectedSlots;
  logo: string;
  returningUserEmailHashes: {[hash: string]: string};
  organization: Organization;
  fetchMeetingQuestionAnswers: (emails: string[])
  => Promise<{ email: string; answers: {[id: number]: MeetingQuestionAnswer} }[]>;
  calendarTimezoneSelected?: TimeZone;
  onCalendarTimezoneSelected: (tz: TimeZone) => void;
  date?: CalDateRange | null;
  onDateChange?: (d: CalDateRange) => void;
  userData?: {
    leaders: Leader[];
    meetingSlots: MeetingSlot[];
    recurringSlots: MeetingSlot[];
    calendarEvents: UICalendarEventConsolidated[];
    meetings: Meetings;
    calendars: Calendar[];
    schedulingPrefs?: SchedulingPreferences;
    selectedLeader: Leader | null,
    onSelectedLeaderChange: (l: Leader | null) => void,
  };
  onLogin: () => void;
};

const PollVotingPage = ({
  loading, pollInfo, ...props
}: PageProps): ReactElement => (
  <CabinetPage
    pageName={'Poll Voting'}
    noHeader
  >
    {!loading && (
      pollInfo ? (
        <PollVoting pollInfo={pollInfo} {...props} />
      ) : (
        <Box hidden={!!pollInfo} display='flex' justifyContent='center' marginTop={5}>
          <Alert severity="warning">
            No poll was found at this link
          </Alert>
        </Box>
      )
    )}
    <Box hidden={!loading} height='100%' display='flex' alignItems='center'>
      <CabSpinner scale={4} color='inherit'/>
    </Box>
  </CabinetPage>
);

export default PollVotingPage;
